import cookies from 'js-cookie'
import events from '@/core/events'
import socket from '@/core/socket'
import cache from '@/core/cache'
import rest from '@/core/rest'
import modal from '@/core/modal'
import moment from '@/core/moment-config'
import hashTracker from '@/core/hashTracker'
import ServerCache from '@/plugins/ServerCache'
import alert from '@/core/alert'
// import GlobalData from '@/../../api/app/Shared/GlobalData'

const cookieOptions = {
  secure: true,
  sameSite: 'none'
}

// We don't need this cookie options in localhost. Otherwise our file download
// system won't work in the localhost.
if (window.location.hostname === 'localhost') {
  delete cookieOptions.secure
  delete cookieOptions.sameSite
}

export default {

  loggedIn: false,
  sideMenuCode: null,
  token: null,
  user: null,
  isReady: false,
  password_expired: false,
  callbacks: [],

  whenReady (callback) {
    if (this.isReady === false) {
      this.callbacks.push(callback)
      return
    }

    callback()
  },

  whenReadyPromise () {
    return new Promise((resolve) => {
      this.whenReady(resolve)
    })
  },

  callCallbacks () {
    for (const index in this.callbacks) {
      this.callbacks[index]()
    }
    this.callbacks = []
  },

  init () {
    if (cookies.get('indication', cookieOptions)) {
      this.loggedIn = true
      this.token = cookies.get('indication', cookieOptions)
      // First, we should check the token is expired or not.
      this.refreshUser(() => {
        this.callCallbacks()
        socket.init()
      })
    }
  },

  initToken (token, cb = () => {}) {
    this.loggedIn = true
    this.token = token.replace(/ /g, '+')
    // First, we should check the token is expired or not.
    this.refreshUserByToken(() => {
      this.callCallbacks()
      socket.init()
      cb()
    })
  },

  refreshUserByToken (callback) {
    if (this.token && this.token.length > 0) {
      rest.get('auth/refreshByToken', { token: this.token }, (response) => {
        cookies.set('indication', this.token, Object.assign(
          {}, cookieOptions, { expires: response.data.stay_logged_in_hours / 24 }))

        this.refresh(response.data)
        // if (!this.password_expired) {
        //   window.router.push({ name: 'renew' })
        // } else {
        window.router.push({ name: 'home' })
        // }
        if (typeof callback === 'function') {
          callback()
        }
        ServerCache.init(response.cache)
      })
    }
  },

  refreshUser (callback) {
    const data = {}
    if (window.location.href.includes('zendesk=login')) {
      data.isZendesk = true
    } else if (window.location.href.includes('zendesk=logout')) {
      window.location.search = ''
    } else if (window.location.href.includes('bxp-circle-id')) {
      const url = new URL(window.location.href)
      const fragment = url.hash.substring(1)
      const fragmentParts = fragment.split('?')
      if (fragmentParts.length > 1) {
        const queryString = fragmentParts[1]

        const fragmentParams = new URLSearchParams(queryString)
        data.redirect = fragmentParams.get('redirect')
      }
    }
    if (this.token && this.token.length > 0) {
      rest.get('auth/refresh', data, (response) => {
        if (response.redirect) {
          window.location.href = response.redirect
          return
        }
        this.refresh(response.data)
        // if (!this.password_expired) {
        //   window.router.push({ name: 'renew' })
        // }
        if (typeof callback === 'function') {
          callback()
        }
        ServerCache.init(response.cache)
      })
    }
  },

  isTeamBackoffice (team_id) {
    return this.user && this.user.permittedOwners.some(o => o.id === team_id && o.is_team)
  },

  isAdmin () {
    if (!this.user) {
      return false
    }
    return this.hasRole('administrator') ||
      this.user.email.toLowerCase() === 'info@bauexperts.de' || // TODO: comment out - is not an admin
      this.user.email.toLowerCase() === 'admin@bauexperts.de' ||
      this.user.email.toLowerCase() === 'info@pixelkultur.net'
  },

  isCustomer () {
    return this.hasRole('customer')
  },

  shouldBeAtLeast (roles) {
    if (this.user === null) {
      return false
    }
    return this.user.roles.some((role) => {
      return roles.indexOf(role) > -1
    })
  },

  getCoworkers () {
    const coworkers = []
    if (!this.user) {
      return []
    }
    this.user.permittedOwners.forEach(team => {
      team.workers.forEach(worker => {
        if (coworkers.some(item => item.id === worker.id) === false) {
          coworkers.push(worker)
        }
      })
    })
    coworkers.sort((a, b) => a.name.localeCompare(b.name))
    return JSON.clone(coworkers)
  },

  hasAnyPermittedUser () {
    if (!this.user) {
      return false
    }
    return this.user.permittedOwners.length > 1
  },

  hasRole (role) {
    if (this.user === null) {
      return false
    }
    return this.user.roles.indexOf(role) > -1
  },

  getToken () {
    return this.token
  },

  getUser () {
    return JSON.clone(this.user)
  },

  logout () {
    rest.cancelAllRequests()
    events.fire('auth@onLogout')
    this.isReady = false
    this.loggedIn = false
    this.token = null
    this.user = null
    cookies.remove('indication', cookieOptions)
    cookies.remove('data', cookieOptions)
    socket.close()
    cache.clear()
    hashTracker.clear()
    ServerCache.clear()
    modal.forceCloseAll()
  },

  openTermsModal (user) {
    // Users have to accep the terms and conditions
    if (user.isTermsAccepted === 0) {
      // modal.open({
      //   component: () => import('@/views/user/Terms'),
      //   classes: {
      //     dialog: 'modal-700',
      //     content: 'modal-terms'
      //   },
      //   data: {
      //     cantCloseByBackButton: true,
      //     onAccepted: () => {
      //       this.refreshUser()
      //     }
      //   }
      // })
      // modal.open({
      //   component: () => import('@/views/user/Terms'),
      //   classes: {
      //     dialog: 'modal-full-screen',
      //     content: 'modal-wizard'
      //   },
      //   data: {
      //     grandParentBridge: {
      //       onClick: () => {}
      //     },
      //     cantCloseByBackButton: true,
      //     // withOptions: true,
      //     // withActions,
      //     // options: this.offer,
      //     modalType: 'fullscreen'
      //   },
      //   onClose: () => {
      //   }
      // })
    }
  },

  openInvitationWizard () {
    // Users have to accep the terms and conditions
    modal.open({
      component: () => import('@/views/user/InvitationWizard'),
      classes: {
        dialog: 'modal-700',
        content: 'modal-terms'
      },
      cantCloseByBackButton: true,
      data: {
        onAccepted: () => {
          this.refreshUser()
        }
      }
    })
  },

  login (data) {
    // this.openTermsModal(data.user)
    this.loggedIn = true
    this.token = data.token
    this.user = data.user
    // if (!this.user.name) {
    //   this.openInvitationWizard()
    // }
    this.password_expired = this.getDateDiff(data.user.password_expire_at)
    cookies.set('indication', this.token, Object.assign({}, cookieOptions, { expires: data.stay_logged_in_hours / 24 }))
    this.isReady = true
    this.callCallbacks()
    socket.init()
    ServerCache.init(data.cache)
  },

  update (data) {
    this.user.name = data.name
    this.user.surname = data.surname
    this.user.profilePicture = data.avatar ? data.avatar.signed_url : '/images/avatar.png'
    this.user.title = data.title ? data.title.title : ''
    events.fire('auth@refreshed')
  },

  refresh (data) {
    // this.openTermsModal(data)
    this.user = data
    // if (!this.user.name) {
    //   this.openInvitationWizard()
    // }
    this.password_expired = true // this.getDateDiff(this.user.password_expire_at)
    this.isReady = true
    events.fire('auth@refreshed')
    socket.close()
    socket.init()
    socket.whenReady(() => {
      socket.send('request@menuStats', {})
    })
    this.callCallbacks()
  },

  getDateDiff (expire_date) {
    const now = moment().startOf('day')
    const expire = moment(expire_date).startOf('day')
    return expire.diff(now, 'days') > 0
  },

  hasActiveSubscription () {
    return this.hasPermitted(['EDIT_WORKS'])
  },

  isPermitted (permitted, showAlert = false) {
    return true
    /* if (this.hasPermitted(['EDIT_WORKS']) || permitted) {
      return true
    }
    if (showAlert) {
      this.showSubscriptionAlert()
    }
    return false */
  },

  hasPermitted (permissions) {
    if (!this.user) {
      return false
    }

    if (this.isAdmin() || permissions.length === 0) {
      return true
    }

    return permissions.every(o => this.user.permissions.some(p => p.value === o))
  },

  async isLimitReached (type) {
    const skipWorkspaceIds = [2578]
    if (!this.hasActiveSubscription() && !skipWorkspaceIds.includes(this.user.selectedWorkspace.id)) {
      try {
        const response = await rest.getSync('auth/free-limits')
        if (response[type].current >= response[type].total) {
          alert.subscribeWarningLimit(this.isWorkspaceAdmin()).then((result) => {
            if (result.isConfirmed && this.isWorkspaceAdmin()) {
              window.router.push({ name: 'profileSettings', params: { type: 'subscription' } })
            }
          })
          return true
        }
      } catch {
        alert.error('Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.')
        return true
      }
    }
    return false
  },

  isWorkspaceAdmin () {
    return this.user.selectedWorkspace.parent_id === this.user.id
  },

  showSubscriptionAlert () {
    alert.subscribeUpgrade(this.isWorkspaceAdmin()).then((result) => {
      if (result.isConfirmed && this.isWorkspaceAdmin()) {
        window.router.push({ name: 'profileSettings', params: { type: 'subscription' } })
      }
    })
  },

  async isInTeamBO (teamId) {
    await this.whenReadyPromise()
    return this.user.permittedOwners.find(o => o.is_team && o.id === parseInt(teamId))
  },

  async isInTeamSV (teamId) {
    await this.whenReadyPromise()
    return this.user.teamMemberships.find(o => o.id === parseInt(teamId))
  },

  async isTeamAdmin (teamId) {
    const team = await this.isInTeamBO(teamId)
    return team && team.workers && team.workers.some(o => o.role === 'Owner' && o.id === this.user.id)
  },

  isTester () {
    return rest.isLocal() || [
      'admin@bauexperts.de',
      'info@bauexperts.de',
      'andreas.paas',
      'viktoria.rose',
      'ali.altunay',
      'necati.papakci',
      'can.friedhoff',
      'leyla.friedhoff',
      'muhammed.koca',
      'emrah.garip'
    ].some(o => this.user.email.includes(o))
  },

  // isNew () {
  //   if (!this.user) {
  //     return false
  //   }

  //   return moment(this.user.selectedWorkspace.created_at).toDate() >= GlobalData.stripeUpdateDate
  // }

}
